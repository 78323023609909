<template>
    <div class="basics-info flex-align">
        <div class="userinfo flex-align">
            <img class="avatar csp" :src="downloadURL + userInfo.avatar" :onerror="defaultAvatar" alt
                @click="$router.push('/my')" />
            <div>
                <p class="userinfo-name">
                    {{ userInfo.name || '默认用户' }}
                    <span class="vip">1</span>
                </p>
                <p class="userinfo-title" v-if="userInfo.titleName">
                    {{ userInfo.titleName }}</p>
                <template v-if="currentProject.id == 735 && role == 'teacher'"></template>
                <div class="info_center" v-else>
                    <el-select @change="changeRole" v-model="activeRole" placeholder="切换角色" class="select-role">
                        <el-option v-for="item in roleList" :key="item.value" :label="item.role" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="achievement" :style="{ visibility: activeRole == 'expert' ? 'hidden' : 'hidden' }">
            <p class="achieve-score"><span class="achieve-unit">{{ $route.query.type == 'yanshi' ? '优秀' : '暂无成绩' }}</span></p>
            <p class="achieve-name">考核成绩</p>
        </div>
        <ul class="range-time">
            <li class="reage-time-item"><span class="range-time-title"><i
                        class="el-icon-time"></i>项目开始时间</span>{{ $route.query.type == 'yanshi' ? '2021-10-10' : dayjs(currentProject.periodStartTime).format('YYYY-MM-DD') }}
            </li>
            <li class="reage-time-item"><span class="range-time-title"><i
                        class="el-icon-time"></i>项目结束时间</span>{{ $route.query.type == 'yanshi' ? '2022-06-30' : dayjs(currentProject.periodFinishTime).format('YYYY-MM-DD') }}
            </li>
        </ul>
    </div>
</template>

<script>
import {
    mapState
} from "vuex";
export default {
    data() {
        return {
            activeRole: '',
        }
    },
    computed: {
        ...mapState({
            role: state => state.user.role,
            roleList: state => state.user.userRoleList,
            userInfo: state => state.user.userInfo,
            defaultAvatar: state => state.user.defaultAvatar,
            currentProject: state => state.user.currentProject
        }),
    },
    watch: {
        'role': {
            handler(newVal, oldVal) {
                // console.log('currentRole', newVal);
                if (!newVal) return;
                this.activeRole = newVal;
            },
            immediate: true,
        }
    },
    methods: {
        changeRole() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            // sessionStorage.setItem('role', this.activeRole);
            this.$store.commit('getRole', this.activeRole);
            this.roleList.map(item => {
                if (this.activeRole == item.value) {
                    // sessionStorage.setItem('roleId', item.id);
                    this.$store.commit('getRoleId', item.id);
                }
            })
            let pathList = {
                'leader': '/home/leader_home',
                'school': '/home/leader_home',
                'manager': '/home/weekly',
            };

            let path = pathList.hasOwnProperty(this.activeRole) ? pathList[this.activeRole] : '/home/schedule'
            this.$router.push(path);
            loading.close();
        },
    },
    mounted() {
        this.activeRole = this.role;
    }
}
</script>

<style lang="less" scoped>
.basics-info {
    box-shadow: 0px 2px 10px 0px #F5F8FA;
    border-radius: 3px;
    padding: 18px 0 18px 50px;
    margin-bottom: 25px;
    background-image: url("~@/assets/images/home/basic-info.jpg");
    background-size: 100% 100%;

    .userinfo {
        line-height: 1;
        // margin-right: 100px;
        width: 280px;

        .avatar {
            width: 73px;
            height: 73px;
            border-radius: 50%;
            margin-right: 27px;
        }

        .userinfo-name {
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 9px;

            .vip {
                color: #FCD012;

                &::before {
                    content: ' ';
                    display: inline-block;
                    width: 17px;
                    height: 15px;
                    background-image: url("~@/assets/images/home/V@2x.png");
                    background-size: 100% 100%;
                }
            }
        }

        .userinfo-title {
            color: #999;
            font-size: 12px;
            display: inline-block;
            margin-right: 28px;
        }

        .info_center {
            display: inline-block;
        }

        /deep/ .select-role {
            .el-input__inner {
                width: 80px;
                border: 0px;
                padding-left: 0;
                font-size: 12px;
                color: #999;
                line-height: 26px;
                height: 26px;
            }

            .el-input__icon {
                line-height: 24px;
                font-size: 12px;
            }
        }
    }

    .achievement {
        margin-right: 33px;
        line-height: 1;

        .achieve-score {
            color: #249BFD;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 15px;

            .achieve-unit {
                font-size: 15px;
            }
        }

        .achieve-name {
            &::before {
                content: ' ';
                display: inline-block;
                width: 17px;
                height: 13px;
                margin-right: 7px;
                vertical-align: -2px;
                background-image: url("~@/assets/images/home/cup.png");
                background-size: 100% 100%;
            }

            &::after {
                content: '（成绩非实时数据）';
                display: inline-block;
                font-size: 12px;
                color: #C4C4C4;
                margin-left: 5px;
            }
        }
    }

    .range-time {
        border-left: 1px solid #eee;
        padding-left: 35px;

        .reage-time-item {
            margin-bottom: 15px;
            font-size: 13px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .range-time-title {
            background-color: #F2F7FF;
            border-radius: 3px;
            padding: 0 10px;
            color: #249BFD;
            line-height: 24px;
            display: inline-block;
            margin-right: 13px;
        }

        .el-icon-time {
            margin-right: 4px;
            font-size: 12px;
            font-weight: 600;
        }
    }

    @media screen and (min-width:1250px) {
        padding: 22px 0 22px 60px;
        margin-bottom: 30px;

        .userinfo {
            // margin-right: 160px;
            width: 375px;

            .avatar {
                width: 88px;
                height: 88px;
                margin-right: 33px;
            }

            .userinfo-name {
                font-size: 20px;
                margin-bottom: 12px;
            }

            .userinfo-title {
                font-size: 12px;
                margin-right: 34px;
            }
        }

        .achievement {
            margin-right: 40px;

            .achieve-score {
                color: #249BFD;
                font-size: 36px;
                margin-bottom: 18px;

                .achieve-unit {
                    font-size: 18px;
                }
            }

            .achieve-name {
                &::before {
                    content: ' ';
                    width: 20px;
                    height: 16px;
                    margin-right: 8px;
                }
            }
        }

        .range-time {
            padding-left: 42px;

            .reage-time-item {
                margin-bottom: 18px;
            }

            .range-time-title {
                padding: 0 12px;
                line-height: 29px;
                margin-right: 17px;
            }
        }
    }
}
</style>