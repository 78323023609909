import { render, staticRenderFns } from "./statistics-count.vue?vue&type=template&id=58555554&scoped=true"
import script from "./statistics-count.vue?vue&type=script&lang=js"
export * from "./statistics-count.vue?vue&type=script&lang=js"
import style0 from "./statistics-count.vue?vue&type=style&index=0&id=58555554&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58555554",
  null
  
)

export default component.exports