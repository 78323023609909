<template>
  <div class="personal_info" v-if="show">
    <ul class="flex-align-between">
      <li class="data-item flex-column-center" :class="item.icon" v-for="(item,index) in currentList" :key="index">
        <p class="data-count">{{item.count}} <span>{{['同步课程数量','异步课程数量','作业成果'].indexOf(item.name)!==-1?'个':'分钟'}}</span> </p>
        <p class="data-name">{{item.name}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  export default {
    props: ['projectId'],
    data() {
      return {
        show: true, //没数据时候不显示
        currentList: [],
        teacherList: [{
            name: '同步课程数量',
            title: 'total_my_course',
            count: 0,
            icon: 'course'
          },
          {
            name: '异步课程数量',
            title: 'total_finish_work',
            count: 0,
            icon: 'work'
          },
          {
            name: '作业成果',
            title: 'total_join_activity',
            count: 0,
            icon: 'activity'
          },
          {
            name: '学习总时长',
            title: 'total_score',
            count: 0,
            icon: 'score'
          },
          {
            name: '网络学习时长',
            title: 'total_honor',
            count: 0,
            icon: 'honor'
          },
        ],
        expertList: [{
            name: '同步课程数量',
            title: 'total_teach',
            count: 0,
            icon: 'teach'
          },
          {
            name: '异步课程数量',
            title: 'total_release_work',
            count: 0,
            icon: 'work'
          },
          {
            name: '作业成果',
            title: 'total_org_activity',
            count: 0,
            icon: 'activity'
          },
          {
            name: '学习总时长',
            title: 'total_check_work',
            count: 0,
            icon: 'check'
          },
          {
            name: '网络学习时长',
            title: 'total_file',
            count: 0,
            icon: 'file'
          },
        ],
        leaderList: [{
            name: '项目学校',
            title: 'total_school',
            count: 0,
            icon: 'school'
          },
          {
            name: '参训学员',
            title: 'total_teacher',
            count: 0,
            icon: 'students'
          },
          {
            name: '专家团队',
            title: 'total_expert',
            count: 0,
            icon: 'team'
          },
          {
            name: '完成作业',
            title: 'total_finish_work',
            count: 0,
            icon: 'work'
          },
          {
            name: '课程数量',
            title: 'total_count_course',
            count: 0,
            icon: 'teach'
          },
        ]
      };
    },
    computed: {
      ...mapState({
        role: state => state.user.role,
        userId: state => state.user.userInfo.id,
      })
    },
    watch: {
      'role': {
        handler(newVal, oldVal) {
          if (!newVal) return;
          this.getInfoList();
        },
        immediate: true,
      }
    },
    methods: {
      // 获取个人统计数据 [根据身份匹配对应列表]
      async getInfoList() {
        let resData;
        if (this.role == 'expert') {
          let data = {
            projectId: this.projectId
          }
          resData = await this.$Api.Course.personCountExpert(data);
        } else if (this.role == 'teacher') {
          let data = {
            projectId: this.projectId,
            userId: this.userId,
            page: 1,
            limit: 10
          }
          resData = await this.$Api.Course.personCountTea(data);
        } else {
          resData = await this.$Api.Course.personCount();
        }
        // console.log('resData---', resData);
        let listMap = {
          'student': 'teacherList',
          'teacher': 'teacherList',
          'parent': 'teacherList',
          'normal': 'teacherList',
          'expert': 'expertList',
          'assistant': 'expertList',
          'leader': 'leaderList',
          'school': 'leaderList',
        }
        if (!resData.data || JSON.stringify(resData.data) === '[]' || !listMap.hasOwnProperty(this.role)) {
          this.currentList = [];
          this.show = false;
          return;
        }
        this.show = true;
        let listName = listMap[(this.role)];
        this.currentList = this[listName];
        this.currentList.map(item => {
          if (this.role == 'teacher') {
            // console.log('专家统计数据--', resData);
            if (item.name === '同步课程数量') {
              item.count = resData.data.records[0].liveCourseCount
            } else if (item.name === '异步课程数量') {
              item.count = resData.data.records[0].recordCourseCount
            } else if (item.name === '作业成果') {
              item.count = resData.data.records[0].questCount
            } else if (item.name === '学习总时长') {
              item.count = Math.floor((resData.data.records[0].liveTime+resData.data.records[0].replayTime+resData.data.records[0].recordTime)/60)
            } else if (item.name === '网络学习时长') {
              item.count = Math.floor(resData.data.records[0].netCourseTime) 
            }
          } else if (this.role == 'expert') {
            // console.log('参训教师统计数据---', resData);
            if (item.name === '同步课程数量') {
              item.count = resData.data.liveCount
            } else if (item.name === '异步课程数量') {
              item.count = resData.data.recordCount
            } else if (item.name === '作业成果') {
              item.count = resData.data.questAnswerCount
            } else if (item.name === '学习总时长') {
              item.count = Math.floor(resData.data.studyTime/60) 
            } else if (item.name === '网络学习时长') {
              item.count = Math.floor(resData.data.studyNetTime)
            }
          } else {
            resData.data.map(each => {
              if (item.title === each.name) {
                item.count = each.num
              }
            })
          }
        })
      }
    },
  };
</script>

<style lang="less" scoped>
  ul {
    margin: 0 auto;
    // width: 990px;
    margin: 27px auto;
    // transition: all 1s;
  }

  .data-item {
    position: relative;
    box-sizing: border-box;
    // padding: 12px 0 0 71px;

    height: 67px;
    width: 176px;
    color: #fff;
    border-radius: 3px;

    .data-count {
      font-size: 25px;
      font-weight: 500;
      line-height: 26px;
      span{
        font-size: 17px;
      }
    }

    .data-name {
      font-size: 12px;
    }

    &::after {
      content: ' ';
      position: absolute;

      width: 91px;
      height: 43px;
      background-image: url("../../assets/images/data/wave.png");
      bottom: -5px;
      right: 0;
    }

    &::before {
      content: ' ';
      position: absolute;
      width: 53px;
      height: 53px;
      //  bottom: -9px;
      left: 11px;
      background-size: 100%;
    }

    &:nth-child(1) {
      background-color: #5473E8;
      box-shadow: 0px 6px 17px 0px rgba(84, 115, 232, .41);

      &.course::before {
        background-image: url("../../assets/images/data/course.png");
      }

      &.teach::before {
        background-image: url("../../assets/images/data/teach.png");
      }

      &.school::before {
        background-image: url("../../assets/images/data/school.png");
      }
    }

    &:nth-child(2) {
      background-color: #36C2CF;
      box-shadow: 0px 6px 17px 0px rgba(54, 194, 207, .41);

      &.work::before {
        background-image: url("../../assets/images/data/work.png");
      }

      &.students::before {
        background-image: url("../../assets/images/data/students.png");
      }
    }

    &:nth-child(3) {
      background-color: #F6B926;
      box-shadow: 0px 6px 17px 0px rgba(230, 155, 53, .41);

      &.activity::before {
        background-image: url("../../assets/images/data/activity.png");
      }

      &.team::before {
        background-image: url("../../assets/images/data/team.png");
      }
    }

    &:nth-child(4) {
      background-color: #EF5757;
      box-shadow: 0px 6px 17px 0px rgba(239, 87, 87, .41);

      &.score::before {
        background-image: url("../../assets/images/data/score.png");
        bottom: 5px;
      }

      &.check::before {
        background-image: url("../../assets/images/data/check.png");
      }

      &.work::before {
        background-image: url("../../assets/images/data/work.png");
      }
    }

    &:nth-child(5) {
      background-color: #B05BCF;
      box-shadow: 0px 6px 17px 0px rgba(127, 99, 219, .41);

      &.honor::before {
        background-image: url("../../assets/images/data/honor.png");
        bottom: 5px;
      }

      &.file::before {
        background-image: url("../../assets/images/data/file.png");
        bottom: 5px;
      }

      &.teach::before {
        background-image: url("../../assets/images/data/teach.png");
      }
    }



  }

  @media screen and (min-width: 1250px) {
    ul {
      // width: 1200px;
      margin: 32px auto;
    }

    .data-item {
      width: 212px;
      height: 81px;
      // padding: 16px 0 0 86px;

      .data-count {
        font-size: 30px;
        line-height: 30px;
        span{
          font-size: 20px;
        }
      }

      .data-name {
        font-size: 13px;
      }

      &::after {
        width: 110px;
      }

      &::before {
        width: 63px;
        height: 63px;
        left: 16px;
      }
    }
  }
</style>